// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-contact-js": () => import("./../../../src/pages/about-us/contact.js" /* webpackChunkName: "component---src-pages-about-us-contact-js" */),
  "component---src-pages-about-us-equipments-js": () => import("./../../../src/pages/about-us/equipments.js" /* webpackChunkName: "component---src-pages-about-us-equipments-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-fee-2024-js": () => import("./../../../src/pages/fee2024.js" /* webpackChunkName: "component---src-pages-fee-2024-js" */),
  "component---src-pages-fee-js": () => import("./../../../src/pages/fee.js" /* webpackChunkName: "component---src-pages-fee-js" */),
  "component---src-pages-flow-js": () => import("./../../../src/pages/flow.js" /* webpackChunkName: "component---src-pages-flow-js" */),
  "component---src-pages-health-checking-about-course-js": () => import("./../../../src/pages/health-checking/about-course.js" /* webpackChunkName: "component---src-pages-health-checking-about-course-js" */),
  "component---src-pages-health-checking-for-companies-js": () => import("./../../../src/pages/health-checking/for-companies.js" /* webpackChunkName: "component---src-pages-health-checking-for-companies-js" */),
  "component---src-pages-health-checking-index-js": () => import("./../../../src/pages/health-checking/index.js" /* webpackChunkName: "component---src-pages-health-checking-index-js" */),
  "component---src-pages-health-checking-qa-js": () => import("./../../../src/pages/health-checking/qa.js" /* webpackChunkName: "component---src-pages-health-checking-qa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-stuff-blogs-index-js": () => import("./../../../src/pages/stuff-blogs/index.js" /* webpackChunkName: "component---src-pages-stuff-blogs-index-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-stuff-blogs-js": () => import("./../../../src/templates/stuff-blogs.js" /* webpackChunkName: "component---src-templates-stuff-blogs-js" */)
}

